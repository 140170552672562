import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link } from '@chakra-ui/react'
import { defaultLocale } from '../../i18n';

const LocalizedLink = ({ to, locale, ...props }) => {
    let url = locale === defaultLocale ? to : `/${locale}${to}`;
    return (
        <Link as={GatsbyLink} {...props} to={url} />
    )
};

export default LocalizedLink;
