import React from 'react';
import Helmet from 'react-helmet';
import { ChakraProvider, Container, Heading } from '@chakra-ui/react'
import Link from './Link';

class Layout extends React.Component {
  state = {
      theme: null,
  };
  componentDidMount() {
      this.setState({ theme: window.__theme });
      window.__onThemeChange = () => {
          this.setState({ theme: window.__theme });
      };
  }
  renderHeader() {
      const { location, locale, title } = this.props;

      const rootPath = `${__PATH_PREFIX__}/`;

      if (location.pathname === rootPath) {
          return (
              <Heading as="h1" size="2xl">
                  <Link
                      style={{
                          boxShadow: 'none',
                          textDecoration: 'none',
                          color: 'var(--textTitle)',
                      }}
                      to="/"
                      locale={locale}
                  >
                      {title}
                  </Link>
              </Heading>
          );
      } else {
          return (
              <Link
                  border="1px"
                  borderRadius="md"
                  borderColor="blue.500"
                  color="blue.500"
                  px={2}
                  to={'/'}
                  locale={locale}
                  _hover={{
                      textDecoration: 'none',
                      color: 'blue.400',
                      borderColor: 'blue.400'
                  }}
              >
                  ←
              </Link>
          );
      }
  }
  render() {
      const { children, locale } = this.props;

      return (
          <ChakraProvider>
              <div
                  className={`${locale}-wrapper`}
                  style={{
                      color: 'var(--textNormal)',
                      background: 'var(--bg)',
                      transition: 'color 0.2s ease-out, background 0.2s ease-out',
                      minHeight: '100vh',
                  }}
              >
                  <Helmet
                      meta={[
                          {
                              name: 'theme-color',
                              content: this.state.theme === 'light' ? '#ffa8c5' : '#282c35',
                          },
                      ]}
                  />
                  <Container maxW='container.md' mt={8} mb={8}>
                      <header
                          style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginBottom: '2.625rem',
                          }}
                      >
                          {this.renderHeader()}
                          {/* {this.state.theme !== null ? (
              <Toggle
                icons={{
                  checked: (
                    <img
                      src={moon}
                      width="16"
                      height="16"
                      role="presentation"
                      style={{ pointerEvents: 'none' }}
                    />
                  ),
                  unchecked: (
                    <img
                      src={sun}
                      width="16"
                      height="16"
                      role="presentation"
                      style={{ pointerEvents: 'none' }}
                    />
                  ),
                }}
                checked={this.state.theme === 'dark'}
                onChange={e =>
                  window.__setPreferredTheme(
                    e.target.checked ? 'dark' : 'light'
                  )
                }
              />
            ) : (
              <div style={{ height: '24px' }} />
            )} */}
                      </header>
                      {children}
                  </Container>
              </div>
          </ChakraProvider>
      );
  }
}

export default Layout;
